<template>
  <v-navigation-drawer
    id="default-drawer"
    v-model="drawer"
    :dark="dark"
    :right="$vuetify.rtl"
    :mini-variant.sync="mini"
    mini-variant-width="80"
    app
    width="260"
  >
    <div class="px-2">
      <default-drawer-header />
      <v-divider class="mx-3 mb-2" />

      <app-card class="mt-15 mb-10 text-center" color="white" v-if="!mini">
        <v-avatar
          color="success"
          size="100"
          class="rounded-circle elevation-6 mt-n12 d-inline-block"
        >
          <v-icon x-large>
            mdi-account
          </v-icon>
        </v-avatar>
        <v-card-text class="text-center">
          <h4 class="text-h4 mb-3 text--primary">
            {{ $i18n.t("common.logged") }}:
            {{ loggedUsername }}
          </h4>
        </v-card-text>
      </app-card>

      <app-card class="mt-15 mb-10 text-center" color="white" v-if="mini">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              color="success"
              size="55"
              v-bind="attrs"
              v-on="on"
              class="rounded-circle elevation-6 mt-n12 d-inline-block"
            >
              <v-icon large>
                mdi-account
              </v-icon>
            </v-avatar>
          </template>
          <span>{{ $i18n.t("common.logged") }}: {{ loggedUsername }}</span>
        </v-tooltip>
      </app-card>

      <v-divider class="mx-3 mb-2" />

      <default-list :items="items" />
    </div>
    <template #append>
      <div class="pa-0 text-left">
        <!-- <app-btn
          class="text-none mb-4"
          color="white"
          href="https://vuetifyjs.com"
          small
          text
        >
          Documentation
        </app-btn>
 -->
        <div class="px-2">
          <v-list expand nav v-bind="$attrs" v-on="$listeners">
            <v-list-item
              active-class="secondary white--text"
              link
              class="py-1 secondary white--text"
              v-bind="$attrs"
              v-on="$listeners"
              @click="logout()"
            >
              <v-list-item-icon class="my-2 align-self-center">
                <v-icon>mdi-exit-to-app</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{
                  $i18n.t("common.logout")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item>
          <a
            href="http://on-demand.hu"
            class="text-decoration-none"
            ><img src="/on-demand-logo.png" class="on-demand-logo" style="height: 30px"></a>
              </v-list-item-content>
            </v-list-item> -->
          </v-list>
        </div>
      </div>
    </template>
    <div class="pt-12" />
  </v-navigation-drawer>
</template>

<script>
// Utilities
import Authentication from "@/commons/Authentication";
import { get, sync } from "vuex-pathify";

export default {
  name: "DefaultDrawer",

  components: {
    DefaultDrawerHeader: () =>
      import(
        /* webpackChunkName: "default-drawer-header" */
        "./widgets/DrawerHeader"
      ),
    DefaultList: () =>
      import(
        /* webpackChunkName: "default-list" */
        "./List"
      )
  },
  computed: {
    loggedUsername() {
      return this.$store.getters["app/loggedInUserUsername"];
    },
    ...get("user", ["dark", "gradient", "image"]),
    ...get("app", ["items", "version"]),
    ...sync("app", ["drawer", "drawerImage", "mini"]),
    mini: sync("app/mini")
  },
  methods: {
    logout() {
      Authentication.logout()
        .catch(error => {
          console.debug(error);
        })
        .finally(() => {
          this.$router.push("/Login");
        });
    }
  }
};
</script>

<style lang="sass">
#default-drawer
  .v-list-item
    margin-bottom: 8px

  .v-list-item::before,
  .v-list-item::after
    display: none

  .v-list-group__header__prepend-icon,
  .v-list-item__icon
    margin-top: 12px
    margin-bottom: 12px
    margin-left: 4px

  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important
</style>
